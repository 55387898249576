<template>
    <v-select v-model="model" :placeholder="placeholder" class="mb-3 c-select-field" aria-placeholder="Select Member" :options="options" label="title">
        <template v-slot:option="option"> <!-- <span :class="option.icon"></span>-->
            <div class="fl-x fl-a-c">
                <span class="avatar-container mr-2">
                    <div style="width: 0.2rem;height: 0.2rem; background-color: gray;border-radius: 50%"></div>
                </span>
                <span>{{ option.label }}</span></div>
        </template>
    </v-select>
</template>
<script>
export default {
    name: 'CustomOptionsSelectionField',
    data () {
        return {
            model: ''
        };
    },
    props: {
        options: {
            type: Object
        },
        placeholder: {
            type: String
        }
    }
};
</script>
<style scoped lang="scss"> .avatar-container {
    img {
        width: 3.55rem;
        border-radius: 50% !important;
        border: 2px solid white;
    }
}

.c-select-field {
    .vs__dropdown-toggle {

        border: var(--form-control-border-width) solid var(--color-primary-600);
        border: var(--form-control-border-width) solid rgba(0, 122, 204, 0.5);
        min-height: 2.3rem;
        background-color: var(--color-white);
    }
}
</style>
