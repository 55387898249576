<template>
    <div class="px-4 py-3 border-2 border-r--2 pos-r active">
        <p class="font-inter-bold fs-3">{{ title }}</p>
        <p class="font-inter-bold fs-5">{{ amount }}</p>
    </div>
</template>

<script>
export default {
    name: 'MembershipPlanCard',
    props: {
        title: {
            type: String
        },
        amount: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.border-2 {
    position: relative;
    border: var(--border-2) solid var(--border-color);
    :hover, &.active {
        border: var(--border-2) solid var(--color-secondary);
    }
}

p {
    :hover {
        border: none !important;
    }
}
</style>
