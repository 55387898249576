<template>
    <div class="p-3 fl-x-res">
        <div class="w-2 border-rounded bg-4 text-center fl-x-cc mb-3 mb-lg-0"><i class="fa fa-gears"></i></div>
<!--        <div class="w-2 text-center fl-x-cc mb-3 mb-lg-0"><img src="../assets/mobile/home/why.png" alt=""></div>-->
        <div class="ml-3 text-center-res">
            <p class="font-weight-bold mb-2">{{ heading }}</p>
            <p class="d-sm-none">{{ content }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseCard',
    props: {
        heading: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        img: {
            type: Image

        }
    }
};
</script>

<style scoped lang="scss">
.border-rounded {
    border-radius: 50%;
}

.w-20 {
    width: 20% !important;
    height: 20% !important;
}

.w-2 {
    width: 3.5rem;
    height: 3.5rem;
    @media (min-width: 992px) {
        width: 6.5rem;
        height: 3.5rem;
    }
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}

.fl-x-res {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 992px) {
        flex-direction: row !important;
    }
}

.text-center-res {
    text-align: center;
    @media (min-width: 992px) {
        text-align: left;
    }
}
</style>
