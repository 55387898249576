<template>
    <div>
        <div class="container">
            <br>
            <btn text="Button" design="basic-b" size="md" borderRadius="0"/>
            <br><br>
            <btn text="Button" design="basic-a" size="md" borderRadius="0"/>
            <br><br>
            <heading-with-info :bottom-tag="true" title="Upcoming Auctions"
                               info="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio, praesentium."></heading-with-info>
            <br><br>
            <div class="row">
                <div class="col-12 col-lg-4">
                    <!--                    <div v-for="(record,index) in accountMemoPads.records" v-bind:key="index"-->
                    <div>
                        <div class="card b-2 bs-5 border-r-1 px-3 pt-3 pb-1">

                            <div class="mb-3">
                                <!--                                <div class="comments-view" v-html="record.comment">-->
                                <!--                                </div>                                -->
                                <div class="comments-view">
                                    <p class="mb-2 ml-3">Test</p>
                                    <p class="fs--1 text-2">
                                        <span class="mr-2"><i class="fa fa-clock-o"></i></span>
                                        <span class="font-weight-light">NOV 23, 2018, 10.17 A.M</span>
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div class="arrow-down">
                        </div>
                        <div class="fl-x">
                            <div class="c-badge"><small>Timestamp</small></div>
                            <div class="c-badge bg-success"><small>Created By</small></div>
                            <div class="c-badge"><small>Category</small></div>
                            <div class="c-badge"><small>Branch Name</small></div>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-4">
                    <why-choose-card heading="Latest Updations"
                                     content="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta, voluptate!">
                    </why-choose-card>
                </div>
                <div class="col-12 col-lg-4">
                    <member-card heading="Adv Renjith R Nair" role="Director - Legal Affairs"
                                 content="Lorem ipsum dolor sit amet, consectetur adipisicing elit. A blanditiis deserunt dignissimos earum enim expedita modi nostrum omnis optio quasi?"></member-card>
                </div>
                <div class="col-12 col-lg-4">
                    <image-with-shadow></image-with-shadow>
                </div>
                <div class="col-12">
                    <member-large-card image-align="left" icon="fa fa-user" heading="Mr. C K Thankamony"
                                       role="CFO & CRO">
                        <template #content> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium
                            cupiditate distinctio illum nemo omnis, praesentium ratione voluptate! Assumenda, maxime
                            nostrum!
                        </template>
                    </member-large-card>
                </div>
                <div class="col-12 col-lg-4">
                    <news-card categ="business" heading="Adv Renjith R Nair" role="Director - Legal Affairs"
                               content="Lorem ipsum dolor sit amet, consectetur adipisicing elit. A blanditiis deserunt dignissimos earum enim expedita modi nostrum omnis optio quasi?"></news-card>
                </div>
                <div class="col-12 col-lg-4">
                    <testimonial-card
                        img="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                        name="Arun S Pillai" designation="Designation"
                        details-path="/"
                        info="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque illo ipsam laboriosam quaerat suscipit. Dolorum laborum magni neque quas vel!"></testimonial-card>
                </div>
                <div class="col-12 col-lg-4">
                    <short-details-section serial="01" title="Register at Bank e Auctions India.com"
                                           content="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores error incidunt iure omnis sit voluptatum."></short-details-section>
                </div>
                <div class="col-12 col-lg-4">
                    <feature-card title="Quality and Support"
                                  content="Lorem ipsum dolor sit amet, consectetur adipisicing elit. A blanditiis deserunt dignissimos earum enim expedita modi nostrum omnis optio quasi?"></feature-card>
                </div>
                <div class="col-12">
                    <inner-banner>
                    </inner-banner>
                </div>
                <div class="col-12 col-lg-4">
                    <locked-image></locked-image>
                </div>
                <div class="col-12 col-lg-4">
                    <property-video-card
                        bg-image="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                        content="Property details with 360 turn around"></property-video-card>
                </div>
                <div class="col-12">
                    <auction-details-tabs></auction-details-tabs>
                </div>
                <div class="col-12">
                    <auction-items></auction-items>
                </div>
                <div class="col-12 col-lg-4">
                    <auction-download-button icon="fa fa-book"
                                             text="Request Property Data Sheet"></auction-download-button>
                </div>
                <div class="col-12">
                    <premium-membership-supports></premium-membership-supports>
                </div>
                <div class="col-12 col-lg-4">
                    <membership-plan-card title="Basic" amount="0.00/ Year"></membership-plan-card>
                </div>
                <div class="col-12">
                    <faq-section-card heading="Heading">
                        <template #default>
                            <ul>
                                <li>List 1</li>
                                <li>List 2</li>
                                <li>List 3</li>
                            </ul>
                        </template>
                    </faq-section-card>
                </div>
                <div class="col-12">
                    <btn block text="LOGIN TO UPGRADE" size="lg" icon-alignment="right" icon="fa fa-chevron-right"
                         class="btn-bg-gradient-secondary border-r--2 border-none"></btn>
                </div>
                <div class="col-12 col-lg-4 bg-3">
                    <login-heading heading="Login to Bank e Auctions India.com">
                        <template #info>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, tempora?</p>
                        </template>
                    </login-heading>
                </div>
                <div class="col-12 bg-3">
                    <validated-input label="Email ID" class="c-input"></validated-input>
                </div>
                <div class="col-12 bg-3">
                    <custom-options-selection-field :options="optionItems"></custom-options-selection-field>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';
import WhyChooseCard from '@components/WhyChooseCard';
import MemberCard from '@components/home/MemberCard';
import ImageWithShadow from '@components/ImageWithShadow';
import MemberLargeCard from '@components/home/MemberLargeCard';
import NewsCard from '@components/home/NewsCard';
import TestimonialCard from '@components/home/TestimonialCard';
import ShortDetailsSection from '@components/about/ShortDetailsSection';
import FeatureCard from '@components/about/FeatureCard';
import InnerBanner from '@components/InnerBanner';
import LockedImage from '@components/auction-details/LockableImage';
import PropertyVideoCard from '@components/auction-details/PropertyVideoCard';
import AuctionDetailsTabs from '@components/auction-details/AuctionDetailsTabs';
import AuctionItems from '@components/auction/AuctionItems';
import AuctionDownloadButton from '@components/auction-details/AuctionDownloadButton';
import PremiumMembershipSupports from '@components/auction-details/PremiumMembershipSupports';
import MembershipPlanCard from '@components/auction-details/MembershipPlanCard';
import FaqSectionCard from '@components/faq/ListSectionCard';
import LoginHeading from '@components/login/LoginHeading';
import CustomOptionsSelectionField from '@components/registration/CustomOptionsSelectionField';

export default {
    name: 'Components',
    components: {
        CustomOptionsSelectionField,
        LoginHeading,
        FaqSectionCard,
        MembershipPlanCard,
        PremiumMembershipSupports,
        AuctionDownloadButton,
        AuctionItems,
        AuctionDetailsTabs,
        PropertyVideoCard,
        LockedImage,
        InnerBanner,
        FeatureCard,
        ShortDetailsSection,
        TestimonialCard,
        NewsCard,
        MemberLargeCard,
        ImageWithShadow,
        MemberCard,
        WhyChooseCard,
        HeadingWithInfo
    }
};
</script>

<style scoped>

</style>
